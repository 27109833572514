import {
  Component,
  Input,
  Output,
  EventEmitter,
  computed,
  signal,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { StateService } from '../state/state.service';
import { PaginationState } from '../models/Pagination';
import { Router } from '@angular/router';

@Component({
  selector: 'fini-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() noMargin = false;

  @Input() totalItems = 0;
  @Input() itemsPerPageOptions = [25, 50, 100];
  @Output() pageChange = new EventEmitter<number>();
  @Output() itemsPerPageChange = new EventEmitter<number>();

  currentTotalItems = signal<number>(0);
  currentPage = signal<number>(1);
  itemsPerPage = signal<number>(25);
  totalPages = computed(() => {
    const t = Math.floor(this.currentTotalItems() / this.itemsPerPage());

    return t === 0 ? 1 : t;
  });

  @Input() emitInitialValues = false;

  constructor(private state: StateService, private router: Router) {}

  ngOnInit(): void {
    const p = this.state.get<PaginationState>('pagination');
    const route = this.router.url.split('/')[1];

    if (p && p[route]) {
      this.itemsPerPage.set(p[route]?.defaultPageSize);
    } else {
      this.itemsPerPage.set(25);
      this.state.set({
        pagination: { ...p, [route]: { defaultPageSize: 25 } },
      });
    }

    if (this.emitInitialValues) {
      this.itemsPerPageChange.emit(this.itemsPerPage());
      this.pageChange.emit(this.currentPage());
    }
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.totalItems) {
      this.currentTotalItems.set(c.totalItems.currentValue);
    }
  }

  timeout: number = null;
  onPageInputChange(page: number): void {
    if (!page || page < 1 || page > this.totalPages()) {
      this.currentPage.set(1);
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (page >= 1 && page <= this.totalPages()) {
        this.currentPage.set(page);
        this.pageChange.emit(this.currentPage());
      }
    }, 500) as unknown as number;
  }

  onItemsPerPageChange(itemsPerPage: number): void {
    this.itemsPerPage.set(itemsPerPage);
    this.currentPage.set(1); // Reset to the first page when items per page change
    this.itemsPerPageChange.emit(this.itemsPerPage());
    this.pageChange.emit(this.currentPage());
    const route = this.router.url.split('/')[1];
    if (route) {
      this.state.set({
        pagination: {
          ...this.state.get('pagination'),
          [route]: {
            defaultPageSize: parseInt(itemsPerPage as unknown as string),
          },
        },
      });
    }
  }

  goToNextPage(): void {
    if (this.currentPage() < this.totalPages()) {
      this.currentPage.update((val) => val + 1);
      this.pageChange.emit(this.currentPage());
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage() > 1) {
      this.currentPage.update((val) => val - 1);
      this.pageChange.emit(this.currentPage());
    }
  }
}
